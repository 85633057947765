import ApiService from './api.service';

const serviceURL = 'transfer';
const TransferService = {
	/**
	 * Get uploaded file url
	 * @param {Integer} key
	 * @returns {String} uploaded file url
	 */
	get(key, expiration, check_nft = false) {
		return ApiService.query(`${serviceURL}/preview`, {
			params: {
				key: key,
				expiration: expiration,
				check_nft: check_nft,
			},
		});
	},

	/**
	 * Upload file to server
	 * @param {Object} body {doc_content: {file Object}}
	 * @param {Object} config post options
	 * @returns {Object} uploaded file key
	 */
	post(body, config) {
		return ApiService.post(`${serviceURL}/upload`, body, config);
	},

	/**
	 * Delete file
	 * @param {String} key file key
	 * @returns
	 */
	delete(key) {
		let url = `${serviceURL}/delete?key=${key}`;
		return ApiService.delete(url);
	},

	/**
	 * Upload PDF file to server
	 * @param {Object} body {doc_content: {file Object}}
	 * @returns {Object} uploaded PDF file key
	 */
	postPdf(body) {
		return ApiService.post(`${serviceURL}/pdf/s3`, body);
	},

	/**
	 * Upload IMG file to server
	 * @param {Object} content  base64 string
	 * @param {integer} project_id project id
	 * @param {integer} user_id user id
	 * @param {string} file_name file_name
	 * @returns {Object} uploaded img file key
	 */
	postImg(body) {
		body.role = 'message_attachment';
		console.log('body', body);
		return ApiService.post(`${serviceURL}/upload`, body);
	},
	postProfilePicture(body) {
		body.role = 'user_setting';
		console.log('body', body);
		return ApiService.post(`${serviceURL}/upload`, body);
	},
};

export default TransferService;
